import { useBrowserCompability } from "contexts/browserCompability";

import BussWebP from "assets/images/bus-team.webp";
import BussJp2 from "assets/images/bus-team.jp2";
import BussDefault from "assets/images/bus-team.png";

import VolvoWebP from "assets/images/volvo-team.webp";
import VolvoJp2 from "assets/images/volvo-team.jp2";
import VolvoDefault from "assets/images/volvo-team.png";

import CariadWebP from "assets/images/cariad-team.webp";
import CariadJp2 from "assets/images/cariad-team.jp2";
import CariadDefault from "assets/images/cariad-team.png";

import BigDataWebP from "assets/images/big-data-team.webp";
import BigDataJp2 from "assets/images/big-data-team.jp2";
import BigDataDefault from "assets/images/big-data-team.png";

import polestarWebP from "assets/images/polestar-team.webp";
import polestarJp2 from "assets/images/polestar-team.jp2";
import polestarDefault from "assets/images/polestar-team.png";

import AvatarAdiWebP from "assets/images/team/adi.webp";
import AvatarAdiJp2 from "assets/images/team/adi.jp2";
import AvatarAdiDefault from "assets/images/team/adi.jpg";

import AvatarAndersWebP from "assets/images/team/anders.webp";
import AvatarAndersJp2 from "assets/images/team/anders.jp2";
import AvatarAndersDefault from "assets/images/team/anders.jpg";

import AvatarAnnaWebP from "assets/images/team/anna.webp";
import AvatarAnnaJp2 from "assets/images/team/anna.jp2";
import AvatarAnnaDefault from "assets/images/team/anna.jpg";

import AvatarDanielAWebP from "assets/images/team/daniel_a.webp";
import AvatarDanielAJp2 from "assets/images/team/daniel_a.jp2";
import AvatarDanielADefault from "assets/images/team/daniel_a.jpg";

import AvatarDanielKWebP from "assets/images/team/daniel.webp";
import AvatarDanielKJp2 from "assets/images/team/daniel.jp2";
import AvatarDanielKDefault from "assets/images/team/daniel.jpg";

import AvatarDavidWebP from "assets/images/team/david.webp";
import AvatarDavidJp2 from "assets/images/team/david.jp2";
import AvatarDavidDefault from "assets/images/team/david.jpg";

import AvatarEricWebP from "assets/images/team/eric.webp";
import AvatarEricJp2 from "assets/images/team/eric.jp2";
import AvatarEricDefault from "assets/images/team/eric.jpg";

import AvatarOscarAWebP from "assets/images/team/oscarA.webp";
import AvatarOscarAJp2 from "assets/images/team/oscarA.jp2";
import AvatarOscarADefault from "assets/images/team/oscarA.jpg";

import AvatarJesperWebP from "assets/images/team/jesper.webp";
import AvatarJesperJp2 from "assets/images/team/jesper.jp2";
import AvatarJesperDefault from "assets/images/team/jesper.jpg";

import AvatarJesperGWebP from "assets/images/team/jesper_g.webp";
import AvatarJesperGJp2 from "assets/images/team/jesper_g.jp2";
import AvatarJesperGDefault from "assets/images/team/jesper_g.jpg";

import AvatarJohanWebP from "assets/images/team/johan.webp";
import AvatarJohanJp2 from "assets/images/team/johan.jp2";
import AvatarJohanDefault from "assets/images/team/johan.jpg";

import AvatarKristofferWebP from "assets/images/team/kristoffer.webp";
import AvatarKristofferJp2 from "assets/images/team/kristoffer.jp2";
import AvatarKristofferDefault from "assets/images/team/kristoffer.jpg";

import AvatarKristofferAWebP from "assets/images/team/kristoffera.webp";
import AvatarKristofferAJp2 from "assets/images/team/kristoffera.jp2";
import AvatarKristofferADefault from "assets/images/team/kristoffera.jpg";

import AvatarLudwigWebP from "assets/images/team/ludde.webp";
import AvatarLudwigJp2 from "assets/images/team/ludde.jp2";
import AvatarLudwigDefault from "assets/images/team/ludde.jpg";

import AvatarMartinWebP from "assets/images/team/martin.webp";
import AvatarMartinJp2 from "assets/images/team/martin.jp2";
import AvatarMartinDefault from "assets/images/team/martin.jpg";

import AvatarNiklasWebP from "assets/images/team/niklas.webp";
import AvatarNiklasJp2 from "assets/images/team/niklas.jp2";
import AvatarNiklasDefault from "assets/images/team/niklas.jpg";

import AvatarOskarJWebP from "assets/images/team/oskarJ.webp";
import AvatarOskarJJp2 from "assets/images/team/oskarJ.jp2";
import AvatarOskarJDefault from "assets/images/team/oskarJ.jpg";

import AvatarPatrikWebP from "assets/images/team/patrik.webp";
import AvatarPatrikJp2 from "assets/images/team/patrik.jp2";
import AvatarPatrikDefault from "assets/images/team/patrik.jpg";


import AvatarRichardWebP from "assets/images/team/richard.webp";
import AvatarRichardJp2 from "assets/images/team/richard.jp2";
import AvatarRichardDefault from "assets/images/team/richard.jpg";

import AvatarSamuelWebP from "assets/images/team/samuel.webp";
import AvatarSamuelJp2 from "assets/images/team/samuel.jp2";
import AvatarSamuelDefault from "assets/images/team/samuel.jpg";

import AvatarSarahFWebP from "assets/images/team/sarahf.webp";
import AvatarSarahFJp2 from "assets/images/team/sarahf.jp2";
import AvatarSarahFDefault from "assets/images/team/sarahf.jpg";

import AvatarTimWebP from "assets/images/team/tim.webp";
import AvatarTimJp2 from "assets/images/team/tim.jp2";
import AvatarTimDefault from "assets/images/team/tim.jpg";

import AvatarTanWebP from "assets/images/team/tan.webp";
import AvatarTanJp2 from "assets/images/team/tan.jp2";
import AvatarTanDefault from "assets/images/team/tan.jpg";

import BobWebP from "assets/images/bob.webp";
import BobJp2 from "assets/images/bob.jp2";
import BobDefault from "assets/images/bob.jpg";

import CaseWebP from "assets/images/case-2.webp";
import CaseJp2 from "assets/images/case-2.jp2";
import CaseDefault from "assets/images/case-2.jpg";

import CaseDarkWebP from "assets/images/case-dark.webp";
import CaseDarkJp2 from "assets/images/case-dark.jp2";
import CaseDarkDefault from "assets/images/case-dark.jpg";

import CaseJesperWebP from "assets/images/jesper-500.webp";
import CaseJesperJp2 from "assets/images/jesper-500.jp2";
import CaseJesperDefault from "assets/images/jesper-500.jpg";

import CaseJesperDarkWebP from "assets/images/jesper-500-dark.webp";
import CaseJesperDarkJp2 from "assets/images/jesper-500-dark.jp2";
import CaseJesperDarkDefault from "assets/images/jesper-500-dark.jpg";

import CrewWebP from "assets/images/beach.jpg";
import CrewJp2 from "assets/images/beach.jp2";
import CrewDefault from "assets/images/beach.webp";

import CrewDarkWebP from "assets/images/crew-dark.webp";
import CrewDarkJp2 from "assets/images/crew-dark.jp2";
import CrewDarkDefault from "assets/images/crew-dark.jpg";

import Case3WebP from "assets/images/case-3.webp";
import Case3Jp2 from "assets/images/case-3.jp2";
import Case3Default from "assets/images/case-3.jpg";

import LaptopWebP from "assets/images/laptop-magazin.webp";
import LaptopJp2 from "assets/images/laptop-magazin.jp2";
import LaptopDefault from "assets/images/laptop-magazin.jpg";

import PatternBwCornerWebP from "assets/images/pattern-bw-corner.webp";
import PatternBwCornerJp2 from "assets/images/pattern-bw-corner.jp2";
import PatternBwCornerDefault from "assets/images/pattern-bw-corner.png";


import PatternTealWebP from "assets/images/hero-bg.webp";
import PatternTealJp2 from "assets/images/pattern-teal.jp2";
import PatternTealDefault from "assets/images/hero-bg.png";

import PatternDarkWebP from "assets/images/pattern-dark.webp";
import PatternDarkJp2 from "assets/images/pattern-dark.jp2";
import PatternDarkDefault from "assets/images/pattern-dark.png";

import ViewWebP from "assets/images/view.webp";
import ViewJp2 from "assets/images/view.jp2";
import ViewDefault from "assets/images/view.jpg";

import WritingWebP from "assets/images/job.webp";
import WritingWJp2 from "assets/images/job.jp2";
import WritingDefault from "assets/images/job.jpg";

import GasellWebp from "assets/images/gasell.webp";
import GasellJp2 from "assets/images/gasell.jp2";
import GasellDefault from "assets/images/gasell.png";

import Gasell2Webp from "assets/images/gasell2.webp";
import Gasell2Jp2 from "assets/images/gasell2.jp2";
import Gasell2Default from "assets/images/gasell2.png";

import Climate1WebP from "assets/images/change.webp";
import Climate1Jp2 from "assets/images/change.jp2";
import Climate1Default from "assets/images/change.jpg";

import Climate2WebP from "assets/images/calf.webp";
import Climate2Jp2 from "assets/images/calf.jp2";
import Climate2Default from "assets/images/calf.jpg";

import Climate3WebP from "assets/images/solar.webp";
import Climate3Jp2 from "assets/images/solar.jp2";
import Climate3Default from "assets/images/solar.jpg";

import GroupWebP from "assets/images/grupp-bild.webp";
import GroupJp2 from "assets/images/grupp-bild.jp2";
import GroupDefault from "assets/images/grupp-bild.jpg";


export const GroupImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${GroupWebP}`;
    case "jp2":
      return `${GroupJp2}`;
    default:
      return `${GroupDefault}`;
  }
};


export const BigDataImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${BigDataWebP}`;
    case "jp2":
      return `${BigDataJp2}`;
    default:
      return `${BigDataDefault}`;
  }
};

export const CariadImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${CariadWebP}`;
    case "jp2":
      return `${CariadJp2}`;
    default:
      return `${CariadDefault}`;
  }
};

export const VolvoImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${VolvoWebP}`;
    case "jp2":
      return `${VolvoJp2}`;
    default:
      return `${VolvoDefault}`;
  }
};

export const VolvoBussImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${BussWebP}`;
    case "jp2":
      return `${BussJp2}`;
    default:
      return `${BussDefault}`;
  }
};


export const PolestarImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${polestarWebP}`;
    case "jp2":
      return `${polestarJp2}`;
    default:
      return `${polestarDefault}`;
  }
};











export const AvatarAndersImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarAndersWebP}`;
    case "jp2":
      return `${AvatarAndersJp2}`;
    default:
      return `${AvatarAndersDefault}`;
  }
};



export const AvatarAdiImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarAdiWebP}`;
    case "jp2":
      return `${AvatarAdiJp2}`;
    default:
      return `${AvatarAdiDefault}`;
  }
};

export const AvatarAnnaImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarAnnaWebP}`;
    case "jp2":
      return `${AvatarAnnaJp2}`;
    default:
      return `${AvatarAnnaDefault}`;
  }
};

export const AvatarDanielKImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarDanielKWebP}`;
    case "jp2":
      return `${AvatarDanielKJp2}`;
    default:
      return `${AvatarDanielKDefault}`;
  }
};

export const AvatarDanielAImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarDanielAWebP}`;
    case "jp2":
      return `${AvatarDanielAJp2}`;
    default:
      return `${AvatarDanielADefault}`;
  }
};

export const AvatarDavidImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarDavidWebP}`;
    case "jp2":
      return `${AvatarDavidJp2}`;
    default:
      return `${AvatarDavidDefault}`;
  }
};

export const AvatarEricImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarEricWebP}`;
    case "jp2":
      return `${AvatarEricJp2}`;
    default:
      return `${AvatarEricDefault}`;
  }
};
export const AvatarKristofferImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarKristofferWebP}`;
    case "jp2":
      return `${AvatarKristofferJp2}`;
    default:
      return `${AvatarKristofferDefault}`;
  }
};

export const AvatarKristofferAImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarKristofferAWebP}`;
    case "jp2":
      return `${AvatarKristofferAJp2}`;
    default:
      return `${AvatarKristofferADefault}`;
  }
};

export const AvatarJesperImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarJesperWebP}`;
    case "jp2":
      return `${AvatarJesperJp2}`;
    default:
      return `${AvatarJesperDefault}`;
  }
};
export const AvatarJesperGImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarJesperGWebP}`;
    case "jp2":
      return `${AvatarJesperGJp2}`;
    default:
      return `${AvatarJesperGDefault}`;
  }
};

export const AvatarJohanImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarJohanWebP}`;
    case "jp2":
      return `${AvatarJohanJp2}`;
    default:
      return `${AvatarJohanDefault}`;
  }
};

export const AvatarLudwigImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarLudwigWebP}`;
    case "jp2":
      return `${AvatarLudwigJp2}`;
    default:
      return `${AvatarLudwigDefault}`;
  }
};

export const AvatarMartinImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarMartinWebP}`;
    case "jp2":
      return `${AvatarMartinJp2}`;
    default:
      return `${AvatarMartinDefault}`;
  }
};
export const AvatarNiklasImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarNiklasWebP}`;
    case "jp2":
      return `${AvatarNiklasJp2}`;
    default:
      return `${AvatarNiklasDefault}`;
  }
};
export const AvatarOskarJImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarOskarJWebP}`;
    case "jp2":
      return `${AvatarOskarJJp2}`;
    default:
      return `${AvatarOskarJDefault}`;
  }
};
export const AvatarPatrikImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarPatrikWebP}`;
    case "jp2":
      return `${AvatarPatrikJp2}`;
    default:
      return `${AvatarPatrikDefault}`;
  }
};
export const AvatarRichardImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarRichardWebP}`;
    case "jp2":
      return `${AvatarRichardJp2}`;
    default:
      return `${AvatarRichardDefault}`;
  }
};

export const AvatarSamuelImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarSamuelWebP}`;
    case "jp2":
      return `${AvatarSamuelJp2}`;
    default:
      return `${AvatarSamuelDefault}`;
  }
};

export const AvatarSarahFImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarSarahFWebP}`;
    case "jp2":
      return `${AvatarSarahFJp2}`;
    default:
      return `${AvatarSarahFDefault}`;
  }
};

export const AvatarTimImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarTimWebP}`;
    case "jp2":
      return `${AvatarTimJp2}`;
    default:
      return `${AvatarTimDefault}`;
  }
};

export const AvatarTanImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarTanWebP}`;
    case "jp2":
      return `${AvatarTanJp2}`;
    default:
      return `${AvatarTanDefault}`;
  }
};

export const AvatarOscarAImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${AvatarOscarAWebP}`;
    case "jp2":
      return `${AvatarOscarAJp2}`;
    default:
      return `${AvatarOscarADefault}`;
  }
};

export const BobImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${BobWebP}`;
    case "jp2":
      return `${BobJp2}`;
    default:
      return `${BobDefault}`;
  }
};

export const CaseImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${CaseWebP}`;
    case "jp2":
      return `${CaseJp2}`;
    default:
      return `${CaseDefault}`;
  }
};

export const CaseDarkImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${CaseDarkWebP}`;
    case "jp2":
      return `${CaseDarkJp2}`;
    default:
      return `${CaseDarkDefault}`;
  }
};

export const CaseJesper = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${CaseJesperWebP}`;
    case "jp2":
      return `${CaseJesperJp2}`;
    default:
      return `${CaseJesperDefault}`;
  }
};

export const CaseJesperDark = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${CaseJesperDarkWebP}`;
    case "jp2":
      return `${CaseJesperDarkJp2}`;
    default:
      return `${CaseJesperDarkDefault}`;
  }
};

export const CrewImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${CrewWebP}`;
    case "jp2":
      return `${CrewJp2}`;
    default:
      return `${CrewDefault}`;
  }
};

export const CrewDarkImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${CrewDarkWebP}`;
    case "jp2":
      return `${CrewDarkJp2}`;
    default:
      return `${CrewDarkDefault}`;
  }
};

export const Case3Image = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${Case3WebP}`;
    case "jp2":
      return `${Case3Jp2}`;
    default:
      return `${Case3Default}`;
  }
};

export const LaptopImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${LaptopWebP}`;
    case "jp2":
      return `${LaptopJp2}`;
    default:
      return `${LaptopDefault}`;
  }
};

export const PatternTealImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${PatternTealWebP}`;
    case "jp2":
      return `${PatternTealJp2}`;
    default:
      return `${PatternTealDefault}`;
  }
};

export const PatternDarkImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${PatternDarkWebP}`;
    case "jp2":
      return `${PatternDarkJp2}`;
    default:
      return `${PatternDarkDefault}`;
  }
};

export const PatternBwCornerImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${PatternBwCornerWebP}`;
    case "jp2":
      return `${PatternBwCornerJp2}`;
    default:
      return `${PatternBwCornerDefault}`;
  }
};

export const ViewImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${ViewWebP}`;
    case "jp2":
      return `${ViewJp2}`;
    default:
      return `${ViewDefault}`;
  }
};

export const WritingImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${WritingWebP}`;
    case "jp2":
      return `${WritingWJp2}`;
    default:
      return `${WritingDefault}`;
  }
};

export const GasellImage = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${GasellWebp}`;
    case "jp2":
      return `${GasellJp2}`;
    default:
      return `${GasellDefault}`;
  }
};
export const GasellImage2 = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${Gasell2Webp}`;
    case "jp2":
      return `${Gasell2Jp2}`;
    default:
      return `${Gasell2Default}`;
  }
};

export const ClimateImage1 = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${Climate1WebP}`;
    case "jp2":
      return `${Climate1Jp2}`;
    default:
      return `${Climate1Default}`;
  }
};
export const ClimateImage2 = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${Climate2WebP}`;
    case "jp2":
      return `${Climate2Jp2}`;
    default:
      return `${Climate2Default}`;
  }
};

export const ClimateImage3 = (): string => {
  const compability = useBrowserCompability();
  switch (compability.imageFormat) {
    case "webP":
      return `${Climate3WebP}`;
    case "jp2":
      return `${Climate3Jp2}`;
    default:
      return `${Climate3Default}`;
  }
};
