import React, { useState } from "react";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import loadable from "@loadable/component";
import { ClimateView } from "components/climate";
import { ScrollToTop } from "components/shared/scrollToTop";
import {
  AppBar,
  Toolbar,
  Box,
  Drawer,
  ClickAwayListener,
  ThemeProvider,
  IconButton,
  Hidden,
} from "@material-ui/core";
import CompabilityProvider from "contexts/browserCompability";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import MenuIcon from "@material-ui/icons/Menu";
import styled from "@emotion/styled";

import { Colors } from "colors";
import logo from "assets/images/logo-black.svg";
import darkLogo from "assets/images/logo-white.svg";

import { theme, darkTheme } from "theme";
import { Footer } from "components/shared/footer";
import { css } from "@emotion/core";

import ThemeModeProvider, { useDarkMode, isDarkMode } from "contexts/themeMode";
import HomeView from "components/home";

const AboutView = loadable(() => import("components/about"));
const CaseView = loadable(() => import("components/case"));
const JobsView = loadable(() => import("components/jobs"));

const AppWrapper = styled.div({
  paddingTop: 60,
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-end",
  "@media (max-width: 960px)": {
    paddingTop: 55,
  },
});

const Navigation = styled(Toolbar)({
  backgroundColor: Colors.white,
  justifyContent: "space-between",
});

const Logo = styled(Link)({
  backgroundImage: `url(${logo})`,
  height: 36,
  width: 36,
  backgroundSize: "contain",
  backgroundPosition: "50%",
  backgroundRepeat: "no-repeat",
  display: "inline-block",
});

const DarkLogo = styled(Link)({
  backgroundImage: `url(${darkLogo})`,
  height: 36,
  width: 36,
  backgroundSize: "contain",
  backgroundPosition: "50%",
  backgroundRepeat: "no-repeat",
  display: "inline-block",
});

const NavBox = styled(Box)(
  css`
    flex-direction: row;
    flex-wrap: nowrap;
     @media (max-width: 960px){
    div:nth-of-type(1n) {
      flex: 0.2;
    }
    div:nth-of-type(2n) {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0.2;
    }
    div:nth-of-type(3n) {
      flex: 0.2;
    }
  }
    @media (min-width: 960px) {
      max-width: 1200px,
      margin-left: auto,
      margin-right: auto,
      flex: 1;
      flex-direction: row;
      align-content: stretch;  
    }
  `
);

const linkBase = css`
  text-transform: uppercase;
  text-decoration: none;
`;

const navLinkStyle = css`
  ${linkBase}
  font-size: 9pt;
  font-weight: 800;
  margin: 0.5rem 2rem;
  &:hover {
    border-bottom: 2px solid ${Colors.black};
  }
  color: ${Colors.black};
`;

const sideLinkStyle = css`
  ${linkBase}
  margin: 0;
  padding: 1rem 2rem;
  font-weight: 500;
  &:after {
    content: "»";
    margin-left: 0.5rem;
  }
  color: ${Colors.white};
`;

const NavLink = styled(Link)(
  (props) => css`
    ${navLinkStyle}
    color: ${props.color};
    &:hover {
      border-bottom: 2px solid ${props.color};
    }
  `
);

const SideLink = styled(Link)(
  (props) => css`
    ${sideLinkStyle}
    color: ${props.color}
  `
);

const NavHashLink = styled(HashLink)(
  (props) => css`
    ${navLinkStyle}
    color: ${props.color};
    &:hover {
      border-bottom: 2px solid ${props.color};
    }
  `
);

const SideHashLink = styled(HashLink)(
  (props) => css`
    ${sideLinkStyle}
    color: ${props.color}
  `
);

const SideHashLinkExternal = styled('a')(
  (props) => css`
    ${sideLinkStyle}
    color: ${props.color}
  `
);

const ExternalLink = styled('a')(
  (props) => css`
    ${navLinkStyle}
    color: ${props.color};
    &:hover {
      border-bottom: 2px solid ${props.color};
    }
  `
);

const Links = ({
  direction,
  onNavigate,
}: {
  direction: "column" | "row";
  onNavigate?: () => void;
}) => {
  const handleNavigate = () => {
    onNavigate && onNavigate();
  };

  //const darkMode = useDarkMode();
  const darkMode = false;

  return (
    <Box display="flex" alignContent="center" flexDirection={direction}>
      {direction === "row" ? (
        <>
          <NavLink
            onClick={handleNavigate}
            to="/case"
            color={darkMode ? Colors.white : Colors.black}
          >
            Case
          </NavLink>
          <NavHashLink
            onClick={handleNavigate}
            to="/om#dna"
            color={darkMode ? Colors.white : Colors.black}
          >
            Vår Värdegrund
          </NavHashLink>
          <NavHashLink
            onClick={handleNavigate}
            to="/klimat"
            color={darkMode ? Colors.white : Colors.black}
          >
            Vi är Klimatsmarta
          </NavHashLink>
          <ExternalLink
            href="https://jobs.logikfabriken.se"
            color={darkMode ? Colors.white : Colors.black} 
          >
            Jobba hos oss
          </ExternalLink>
          <NavHashLink
            onClick={handleNavigate}
            to="/jobb#kontakt"
            color={darkMode ? Colors.white : Colors.black}
          >
            Kontakt
          </NavHashLink>
        </>
      ) : (
        <>
          <SideLink onClick={handleNavigate} to="/">
            Hem
          </SideLink>
          <SideLink onClick={handleNavigate} to="/case">
            Case
          </SideLink>
          <SideHashLink onClick={handleNavigate} to="/om#dna">
            Vår Värdegrund
          </SideHashLink>
          <SideLink onClick={handleNavigate} to="/klimat">
            Vi är Klimatsmarta
          </SideLink>
          <SideHashLinkExternal href="https://jobs.logikfabriken.se">
            Jobba hos oss!
          </SideHashLinkExternal>
          <SideHashLink onClick={handleNavigate} to="/jobb#kontakt">
            Kontakt
          </SideHashLink>
        </>
      )}
    </Box>
  );
};

const App = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleClickAway = () => {
    setMenuOpen(false);
  };

  //const darkMode = isDarkMode();
  const darkMode = false;

  return (
    <CompabilityProvider>
      <ThemeModeProvider>
        <ThemeProvider theme={darkMode ? darkTheme : theme}>
          <BrowserRouter>
            <ClickAwayListener onClickAway={handleClickAway}>
              <AppWrapper>
                <AppBar position="fixed">
                  <Navigation>
                    <NavBox
                      display="flex"
                      flexGrow="1"
                      justifyContent="space-between"
                    >
                      <Hidden mdUp>
                        <div>
                          <IconButton
                            onClick={() => setMenuOpen(true)}
                            aria-label="open menu"
                          >
                            <MenuIcon />
                          </IconButton>
                        </div>
                      </Hidden>
                      <Hidden smDown>
                        {darkMode ? <DarkLogo to="/" /> : <Logo to="/" />}
                        <Links direction="row" />
                      </Hidden>
                      <Hidden mdUp>
                        <div>
                          <Logo to="/" />
                        </div>
                      </Hidden>
                      <Hidden mdUp>
                        <div></div>
                      </Hidden>
                    </NavBox>
                  </Navigation>
                </AppBar>
                <Drawer open={menuOpen}>
                  <Box display="flex" marginLeft="1rem">
                    <IconButton color="primary" onClick={handleClickAway}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Box>
                  <Links onNavigate={handleClickAway} direction="column" />
                </Drawer>
                <ScrollToTop />
                <Switch>
                  <Route exact path="/" component={HomeView} />
                  <Route exact path="/case" component={CaseView} />
                  <Route exact path="/om" component={AboutView} />
                  <Route exact path="/klimat" component={ClimateView} />
                  <Route exact path="/jobb" component={JobsView} />
                </Switch>
                <Footer />
              </AppWrapper>
            </ClickAwayListener>
          </BrowserRouter>
        </ThemeProvider>
      </ThemeModeProvider>
    </CompabilityProvider>
  );
};

export default App;
