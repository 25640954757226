export interface IColors {
  white: string;
  black: string;
  darkTeal: string;
  softTeal: string;
  cleanGrey: string;
  orange: string;
  veryDarkTeal: string;
  darkGrey: string;
}

export const Colors: IColors = {
  white: "#FFFFFF",
  black: "#000000",
  darkTeal: "#58988E",
  softTeal: "#79B6AA",
  cleanGrey: "#F1F1F2",
  orange: "orange",
  veryDarkTeal: "#13201e",
  darkGrey: "#101010"
};
