import React from "react";
import Box from "@material-ui/core/Box";
import {
  Container,
  Block,
  ResponsiveBlock,
  WideBlock,
  ColoredBox,
  ResponsivePictureBlock,
} from "components/shared/block";
import { Header, Body } from "components/shared/typography";
import { Colors } from "colors";
import {
  BobImage,
  ClimateImage1,
  ClimateImage2,
  ClimateImage3,
  LaptopImage,
  ViewImage,
} from "components/images";
import { useDarkMode } from "contexts/themeMode";

export const ClimateView = () => {
  const darkMode = useDarkMode();
  return (
    <>

      <Block
        backgroundColor={darkMode ? "black" : "white"}
        id="breakit"
        minHeight="30vh"
      >
        <Container minHeight="30vh">
          <ResponsiveBlock minHeight="30vh">
            <Box
              maxWidth={400}
              paddingTop={4}
              paddingRight={4}
              paddingBottom={4}
              minHeight={50}
              justifyContent="center"
            >
              <Header
                variant="h1"
                color={darkMode ? "white" : "black"}
                underline={{ width: 240, color: "darkTeal" }}
                textAlignment="left"
              >
                Klimatneutrala sedan 2019
              </Header>
            </Box>
            <Box maxWidth={500} paddingTop={4} minHeight={150}>
              <Body color={darkMode ? "white" : "black"}>
                Det är viktigt att vi alla tar ansvar för vår miljö och vi aktivt belyser vilken påverkan 
                vi har på klimatat. Sedan 2019 är Logikfabriken klimatneutrala och utför årliga klimatredovisningar 
                för att säkerställa att vi är medvetna om vår påverkan och kan vidta åtgärder för att minska den.
              </Body>
            </Box>
           
          </ResponsiveBlock>
        </Container>
      </Block>
      
      <WideBlock>
        <ColoredBox
          justifyContent="center"
          color={darkMode ? Colors.veryDarkTeal : Colors.darkTeal}
        >
          <Header variant="h2" color={darkMode ? "white" : "white"}>
            Konkreta förändringar
          </Header>
          <Body color={darkMode ? "white" : "white"}>
            <Box display="flex" flexDirection="column">
              <span>- Använda klimatsmart elavtal.</span>
              <span>
                - Stänga av elektronik och släck belysning när vi går för dagen.
              </span>
              <span>- Källsortera för att möjliggöra återvinning.</span>
              <span>
                - Välja vegetariska alternativ på gemensamma aktiviteter och
                afterwork.
              </span>
              <span>- Resa med kollektivtrafik i största möjliga mån.</span>
              <span>- Halvera vårt flygande.</span>
            </Box>
          </Body>
        </ColoredBox>
        <ResponsivePictureBlock backgroundImage={ClimateImage1()} />
      </WideBlock>
      <WideBlock>
        <ResponsivePictureBlock backgroundImage={ClimateImage2()} />
        <ColoredBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          color={Colors.black}
        >
          <Header variant="h2" color="white">
            Beteendemönster
          </Header>
          <Body color="white">
            Det svåraste att förändra är våra beteendemönster. Många av våra
            vardagliga beslut sker ofta per automatik och utan större
            reflektion. Behöver vi alltid ha den senaste mobiltelefonen? Kan vi
            använda datorn ett år till? Logikfabriken har som vana att alltid
            utmana behovet och ställa frågor när något ska köpas in.
          </Body>
        </ColoredBox>
      </WideBlock>
      <WideBlock>
        <ColoredBox
          display="flex"
          flexDirection="column"
          justifyContent="center"
          color={darkMode ? Colors.darkGrey : Colors.cleanGrey}
        >
          <Header variant="h2" color={darkMode ? "white" : "black"}>
            Klimatavtryck
          </Header>
          <Body color={darkMode ? "white" : "black"}>
            Logikfabrikens klimatredovisning för 2022 är utförd och vårt totala
            utsläpp av CO2 är beräknat till 150t. I år har vi valt att stötta
            ett projekt där man bygger en solcellspark i Indien.
          </Body>
        </ColoredBox>
        <ResponsivePictureBlock backgroundImage={ClimateImage3()} />
      </WideBlock>
    </>
  );
};
