import React, { useContext, createContext } from "react";

interface ThemeModeInterface {
  darkMode: boolean;
}

export const isDarkMode = () => {
  return false; // dont allow darkmode yet
  const mode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  return mode;
};

const ThemeModeContext = createContext<ThemeModeInterface>({
  darkMode: false
});

const ThemeModeProvider = (props: any) => {
  const mode = isDarkMode();
  const themeMode = {
    darkMode: mode
  };

  return <ThemeModeContext.Provider value={themeMode} {...props} />;
};

export default ThemeModeProvider;

export const useDarkMode = () => {
  const ctx = useContext(ThemeModeContext);
  return ctx.darkMode;
};
