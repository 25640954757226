import React from "react";
import Box from "@material-ui/core/Box";
import { Caption, Body } from "components/shared/typography";
import { Calculation } from "services/calculator/calculation";
import { formatCurrency } from "services/calculator";
import { CircularProgress } from "@material-ui/core";
import { PieChart, Pie, Cell } from "recharts";

import { Colors } from "colors";
import styled from "@emotion/styled";
import { useDarkMode } from "contexts/themeMode";

export interface CalculatorResultProps {
  values: Calculation | null;
}

const FlexPie = styled(PieChart)({
  display: "flex",
  margin: "0 auto",
});

export const CalculationResult = ({ values }: CalculatorResultProps) => {
  const darkMode = useDarkMode();

  if (!values) {
    return (
      <Box display="flex" justifyContent="center" alignContent="center">
        <CircularProgress variant="indeterminate" color="secondary" />
      </Box>
    );
  }

  const data = [
    { name: "Löneuttag", value: values.grossSalary, color: Colors.darkTeal },
    {
      name: "Sociala avgifter",
      value: values.socialFees,
      color: Colors.softTeal
    },
    {
      name: "Semesterersättning",
      value: values.vacationFund,
      color: Colors.orange
    },
    { name: "Sparad buffert", value: values.buffer, color: Colors.cleanGrey }
  ];

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box marginBottom={2}>
        <table>
          <tbody>
            <tr>
              <td><Body color={darkMode ? "white" : "black"}>{formatCurrency(-1 * values.grossSalary)}</Body></td>
              <td>
                <Caption color="darkTeal">Löneuttag</Caption>
              </td>
            </tr>
            <tr>
              <td><Body color={darkMode ? "white" : "black"}>{formatCurrency(-1 * values.socialFees)}</Body></td>
              <td>
                <Caption color="softTeal">Sociala avgifter</Caption>
              </td>
            </tr>
            <tr>
              <td><Body color={darkMode ? "white" : "black"}>{formatCurrency(-1 * values.vacationFund)}</Body></td>
              <td>
                <Caption color="orange">Semesterersättning</Caption>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <table>
          <tbody>
            <tr>
              <td>
                <Caption>Sparad buffert</Caption>
              </td>
              <td>{formatCurrency(values.buffer)}</td>
            </tr>
          </tbody>
        </table>
      </Box>
      <Box marginBottom={2} justifyContent="center">
        <FlexPie width={280} height={280}>
          <Pie
            dataKey="value"
            data={data.reverse()}
            startAngle={-270}
            cx={135}
            cy={135}
            innerRadius={80}
            outerRadius={120}
            blendStroke={true}
          >
            {data.map(entry => (
              <Cell key={entry.name} fill={entry.color} />
            ))}
          </Pie>
        </FlexPie>
      </Box>
    </Box>
  );
};
