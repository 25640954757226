import React, { useState, useContext, createContext, useEffect } from "react";

interface CompabilityInterface {
  imageFormat: imageSupport;
}

export type imageSupport = "webP" | "jp2" | "default";

const CompabilityContext = createContext<CompabilityInterface>({
  imageFormat: "default"
});

function testWebPSupport() {
  return new Promise(res => {
    const webP = new Image();
    webP.src =
      "data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA";
    webP.onload = webP.onerror = function() {
      res(webP.height === 2);
    };
  });
}

function testJp2Support() {
  return new Promise(res => {
    var JP2 = new Image();
    JP2.src =
      "data:image/jp2;base64,/0//UQAyAAAAAAABAAAAAgAAAAAAAAAAAAAABAAAAAQAAAAAAAAAAAAEBwEBBwEBBwEBBwEB/1IADAAAAAEAAAQEAAH/XAAEQED/ZAAlAAFDcmVhdGVkIGJ5IE9wZW5KUEVHIHZlcnNpb24gMi4wLjD/kAAKAAAAAABYAAH/UwAJAQAABAQAAf9dAAUBQED/UwAJAgAABAQAAf9dAAUCQED/UwAJAwAABAQAAf9dAAUDQED/k8+kEAGvz6QQAa/PpBABr994EAk//9k=";
    JP2.onload = JP2.onerror = function() {
      res(JP2.height === 2);
    };
  });
}

const CompabilityProvider = (props: any) => {
  const [imageSupport, setImageSupport] = useState("default");
  const [webPSupportChecked, setWebPSupportChecked] = useState(false);
  const [jp2SupportChecked, setjp2SupportChecked] = useState(false);
  useEffect(() => {
    testWebPSupport().then(res => {
      if (res) {
        setImageSupport("webP");
      }
      setWebPSupportChecked(true);
    });
    testJp2Support().then(res => {
      if (res) {
        setImageSupport("jp2");
      }
      setjp2SupportChecked(true);
    });
  }, []);

  const compability = {
    imageFormat: imageSupport
  };

  if (!webPSupportChecked || !jp2SupportChecked) return <div></div>;

  return <CompabilityContext.Provider value={compability} {...props} />;
};

export default CompabilityProvider;

export const useBrowserCompability = () => useContext(CompabilityContext);
