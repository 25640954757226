import React, { useState } from "react";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import { Button } from "@material-ui/core";
import {
  Container,
  FullHeightBlock,
  Block,
  ResponsiveBlock,
} from "components/shared/block";
import { Header, Body } from "components/shared/typography";
import styled from "@emotion/styled";

import { Colors } from "colors";

import { Calculator } from "../jobs/calculator";
import { Calculation } from "services/calculator/calculation";
import { CalculationResult } from "../jobs/calculationResult";
import { getSalary } from "services/calculator";

import {
  PatternTealImage,
  PatternDarkImage,

  CrewImage,
  CrewDarkImage,
  GroupImage,
} from "components/images";
import { useDarkMode } from "contexts/themeMode";

const HeroBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  flexWrap: "wrap",
  flexDirection: "column",
  padding: "20px"


});

const HeroHeader =styled(Header)({
  fontSize: "100px",

  lineHeight: "100%",
  "@media (max-width: 960px)": {
    fontSize: "36pt",
  },
  "@media (max-width: 360px)": {
    fontSize: "22pt",
  },
}

);

const HeroText = styled(Body)({
  maxWidth: 800,
  color: Colors.white,
  textShadow: "1px 1px 2px #000",
  fontweight: 400,
  textAlign:"center",

  }

);

const HeroLinks = styled(Box)({
  maxWidth: 760,
  display: "flex",
  flexGrow: 1,
  justifyContent: "center",
  padding: 30,
  marginTop:30,
  
  "@media (max-width: 960px)": {
    padding: 0,
  },
  "@media (max-width: 360px)": {
    padding: 0,
    flexDirection: "column",
    justifyContent: "center",
    flexGrow: 0,
  },
});

const NavLink = styled(Link)`
  color: ${(props) => props.color || Colors.white};
  text-shadow: ${(props) =>
    props.color === Colors.white ? "1px 1px 2px #000" : "none"};
  text-transform: uppercase;
  text-decoration: none;
  border-bottom: ${(props) =>
    props.color === Colors.white ? `2px solid ${Colors.white}` : "none"};
  font-weight: 600;
  &:after {
    content: "»";
    margin-left: 0.5rem;
  }
  @media (max-width: 360px) {
    margin: 10px;
    text-align: center;
  }
`;



const ButtonLink = styled(Link)`
  color: ${(props) => props.color || Colors.black};
  background-color: white;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  cursor: pointer;
  opacity: 0.8;
  margin: 10px;
  padding: 15px;
  &:hover {

  
    background-color: black;
    color:white;
  }
  @media (max-width: 360px) {
    margin: 10px;
    text-align: center;
  }
`;

const ButtonLinkExternal = styled('a')`

color: ${(props) => props.color || Colors.black};
background-color: white;


  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  cursor: pointer;
  opacity: 0.8;
  margin: 10px;
  padding: 15px;
  &:hover {

  
    background-color: black;
    color:white;
  }
  @media (max-width: 360px) {
    margin: 10px;
    text-align: center;
  }
`;

const FullHeightImage = styled(FullHeightBlock)({


"@media (max-width: 960px)": {
  backgroundPositionX: "center",
  backgroundSize:"cover"
},
"@media (max-width: 360px)": {
  backgroundPositionX: "center",
  backgroundSize:"cover"
} });

const HomeView = () => {
  const [calculation, setCalculation] = useState<Calculation | null>(null);
  const darkMode = useDarkMode();

  const updateCalculation = (salary: number, rate: number) => {
    getSalary(salary, rate).then((data) => {
      setCalculation(data);
    });
  };

  return (
    <>
      <FullHeightBlock
        backgroundImage={darkMode ? PatternDarkImage() : PatternTealImage()}
      >
        <Container padding={0}>
          <HeroBox >
            <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center" >
              <Header 
              textAlignment="center"
                variant="h2"
                color="white"
                marginBottom={10}
                justifyContent="center"
              >             
               Välkommen till
              </Header>
            
              <HeroHeader
              variant="h1"
                color="white"
                marginBottom={60}
              >
              LOGIKFABRIKEN
              </HeroHeader>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="center">
              <HeroText
              >
                Nytänkande, roligt och kunskapsdrivet konsultbolag med
                medarbetaren i fokus. Hos Logikfabriken väljer man själv sitt
                uppdrag, sätter sin egen lön, styr över sin egen utveckling och
                får vara med att utveckla världens bästa företag.
              </HeroText>
            </Box>
            <Box display="flex" flexGrow={1} justifyContent="center">
              <HeroLinks>
                <ButtonLink to="/case"  >
                  Case
                </ButtonLink>
                <ButtonLinkExternal href="https://jobs.logikfabriken.se">Jobba med oss
                </ButtonLinkExternal>
                
              </HeroLinks>
            </Box>
          </HeroBox>
        </Container>
      </FullHeightBlock>
    
      
      <Block backgroundColor={darkMode ? "black" : "white"}>
        <Container padding={0}>
          <ResponsiveBlock>
            <Box
              display="flex"
              maxWidth={500}
              flexBasis={"auto"}
              paddingTop={4}
              paddingBottom={4}
              flexGrow={1}
              minHeight={150}
              justifyContent="center"
            >
              <Header
                variant="h1"
                color={darkMode ? "white" : "black"}
                underline={{ width: 300, color: "darkTeal" }}
                textAlignment="left"
              >
                Välkomnande atmosfär och alla på samma villkor
              </Header>
            </Box>
            <Box
              display="flex"
              maxWidth={500}
              flexBasis={"auto"}
              flexGrow={1}
              paddingTop={4}
              paddingBottom={4}
              minHeight={150}
            >
              <Body color={darkMode ? "white" : "black"}>
                Något unikt för Logikfabriken är att alla våra medarbetare har
                exakt samma villkor. Alla medarbetare kan således öppet prata om
                allt som rör uppdrag, ekonomi, pension och timtaxor. Tillsammans
                hjälper vi varandra till det bättre.
              </Body>
            </Box>
          </ResponsiveBlock>
        </Container>
      </Block>
      <FullHeightBlock backgroundColor={darkMode ? "darkGrey" : "cleanGrey"} id="kalkylator">
        <Container>
          <ResponsiveBlock>
            <Box
              display="flex"
              flexDirection="column"
              maxWidth={500}
              justifyContent="center"
            >
    <Header
                variant="h1"
                color={darkMode ? "white" : "black"}
               
                textAlignment="left"
                marginBottom={30}
              >
                Sätt din egen lön
              </Header>
              <Calculator onChange={updateCalculation} />
            </Box>
            <Box display="flex" maxWidth={500}>
              <CalculationResult values={calculation} />
            </Box>
          </ResponsiveBlock>
        </Container>
      </FullHeightBlock>
      <Block backgroundColor={darkMode ? "black" : "white"}>
        <Container>
          <ResponsiveBlock>
            <Box
              display="flex"
              alignItems="center"
              maxWidth={500}
              flexBasis={"auto"}
              paddingTop={4}
              paddingBottom={4}
              flexGrow={1}
              minHeight={150}
              justifyContent="center"
            >
              <Header
                variant="h1"
                color={darkMode ? "white" : "black"}
                underline={{ width: 300, color: "darkTeal" }}
                textAlignment="left"
              >
                Hos oss väljer du uppdraget
              </Header>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              maxWidth={500}
              flexBasis={"auto"}
              flexGrow={1}
              paddingTop={4}
              paddingBottom={4}
              minHeight={350}
            >
              <Body color={darkMode ? "white" : "black"}>
                Tillsammans har vi ett enormt nätverk med kontakter. Vi söker
                vitt och brett och samlar en lista på uppdrag som passar dina
                önskemål. Sen är det din tur att välja det som känns bäst. Det
                är mycket som ska stämma - roliga uppgifter, intressanta
                uppdragsgivare, möjlighet till utveckling och rätt arvode.{" "}
                <strong>Därför är det du som väljer!</strong>
              </Body>
            </Box>
          </ResponsiveBlock>
        </Container>
      </Block>
      <FullHeightImage
        backgroundImage={darkMode ? GroupImage() : GroupImage() }



      ></FullHeightImage>
    </>
  );
};

export default HomeView;
