import { Calculation } from './calculation';

const _serviceUrl =
  'https://logfabsalarycalculator.azurewebsites.net/calculate';

export const formatCurrency = (value: number) => {
  const roundedValue = Math.round(value);
  const formatted = `${roundedValue
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} kr`;
  return formatted;
};
let abortController = new AbortController();
export const getSalary = async (
  salary: number,
  rate: number
): Promise<Calculation> => {
  abortController.abort();
  abortController = new AbortController();
  const url = `${_serviceUrl}?salary=${salary}&wage=${rate}&percentage=0.7`;
  const response = await fetch(url, { signal: abortController.signal });
  if (response.ok) {
    const result = await response.json();
    return result;
  } else {
    return { socialFees: 0, grossSalary: 0, vacationFund: 0, buffer: 0 };
  }
};
