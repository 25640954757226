import { createMuiTheme } from "@material-ui/core";
import { Colors } from "./colors";

export const theme = createMuiTheme({
  palette: {
    background: {
      default: Colors.white
    },
    primary: {
      main: Colors.white
    },
    secondary: {
      main: Colors.darkTeal
    }
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: Colors.darkTeal
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: Colors.white
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: Colors.white
      }
    },
    MuiButton: {
      contained: {
        backgroundColor: Colors.black,
        display: "inline-block",
        maxWidth: 100,
        color: Colors.white,     
        transition: "none",
        "&:hover": {
          backgroundColor: Colors.white,
          color: Colors.black
        }
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'transparent'
      }
    }
  }
});

export const darkTheme = createMuiTheme({
  palette: {
    background: {
      default: Colors.black
    },
    primary: {
      main: Colors.black 
    },
    secondary: {
      main: Colors.softTeal
    }
  },
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: Colors.black,
        color: Colors.softTeal
      }
    },
    MuiIcon: {
      colorPrimary: {
        color: Colors.white
      },
      root: {
        color: Colors.white
      }
    },
    MuiLink: {
      button: {
        color: Colors.white
      }
    },
    MuiIconButton: {
      colorInherit: {
        color: Colors.white
      },
      colorPrimary: {
        color:Colors.white
      },
      root: {
        color: Colors.white
      }
    },
    MuiAppBar: {
      colorDefault: {
        backgroundColor: Colors.black,
        color: Colors.softTeal
      }
    },
    MuiToolbar: {
      root: {
        backgroundColor: Colors.black,
        color: Colors.white
      }      
    },
    MuiButton: {
      contained: {
        backgroundColor: Colors.white,
        display: "inline-block",
        maxWidth: 100,
        color: Colors.black,     
        transition: "none",
        "&:hover": {
          backgroundColor: Colors.black,
          color: Colors.white
        }
      }
    }
  }

});