import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core"
import { Colors, IColors } from "colors";

export interface HeaderProps {
  id?: string;
  color?: keyof IColors;
  textShadow?: string;
  underline?: UnderlineProps;
  marginBottom?: number;
  textAlignment?: AlignSetting;
  display?:string;
  flexDirection?:string;
  justifyContent?:string;
}

export interface UnderlineProps {
  width: string | number;
  color: keyof IColors;
}


const HeaderLevel1 = styled.h1<HeaderProps>((props: HeaderProps) => css`
font-size: 48px;

  text-align: ${props.textAlignment || "center"};
  margin: 0;
  margin-bottom: ${props.marginBottom || 0}px;
  font-family: Oswald Medium;
  color: ${props.color ? Colors[props.color] : Colors.black};

  line-height: 38pt;
  &:after ${props.underline ? css`{
    content: "";
    position: absolute;
    display: block;
    width: ${props.underline.width}px;    
    margin-top: 1rem;
    border-bottom: 8px solid ${Colors[props.underline.color]};
  }` : null};  
  @media (max-width: 960px) {
    font-size: 28pt;
  }
  @media (max-width: 320px) {
    &:after {
      max-width: 240px;
    } 
  }
`);

const HeaderLevel2 = styled.h2<HeaderProps>((props: HeaderProps) => ({
  fontSize: "42px",
  textAlign: props.textAlignment || "center",
  margin: 0,
  marginBottom: `${props.marginBottom || 0}px`,
  fontFamily: "Oswald Light",
  color: props.color ? Colors[props.color] : Colors.black,
  display: props.display || "flex",
  justifyContent: props.justifyContent || "left",
 // flexDirection: props.flexDirection || "column",


  


   
  "@media (max-width: 960px)": {
    fontSize: "18pt"
  },
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

const HeaderLevel3 = styled.h3<HeaderProps>((props: HeaderProps) => ({
  fontSize: "14pt",
  textAlignment:`${props.textAlignment || "center"}`, 
  marginBottom: `${props.marginBottom || 0}px`,
  margin: 0,
  fontFamily: "Titillium Web Bold",
  color: props.color ? Colors[props.color] : Colors.darkTeal,
  textShadow: props.textShadow || "none",
  "@media (max-width: 960px)": {
    fontSize: "12pt"
  },
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export const Header = ({
  variant = "h1",
  children,
  ...props
}: HeaderProps & { variant: "h1" | "h2" | "h3"; children?: ReactNode }) => {
  switch (variant) {
    case "h1":
      return <HeaderLevel1 {...props}>{children}</HeaderLevel1>;
    case "h2":
      return <HeaderLevel2 {...props}>{children}</HeaderLevel2>;
    case "h3":
      return <HeaderLevel3 {...props}>{children}</HeaderLevel3>;
  }
};

export const Body = styled.span<HeaderProps>((props: HeaderProps) => ({
  fontSize: "14pt",
  fontFamily: "Titillium Web Light",
  color: props.color ? Colors[props.color] : Colors.black,
  textShadow: props.textShadow || "none",
  opacity: 0.9
}));

export const Caption = styled.span<HeaderProps>((props: HeaderProps) => ({
  fontSize: "14pt",
  fontFamily: "Titillium Web Light",
  color: props.color ? Colors[props.color] : Colors.black,
  opacity: 0.9,
  textTransform: "uppercase"
}));
