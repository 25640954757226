import React, { useState, useEffect, useRef } from "react";
import Box from "@material-ui/core/Box";
import { Body, Caption } from "components/shared/typography";
import { Slider, DarkSlider } from "./slider";
import _ from "lodash";
import { useDarkMode } from "contexts/themeMode";

function valuetext(value: number) {
  return `${value} kr`;
}

export interface CalculatorProps {
  onChange: (salary: number, rate: number) => void;
}

export const Calculator = ({ onChange }: CalculatorProps) => {
  const [maxSalary, setMaxSalary] = useState<number>(50000);
  const [salary, setSalary] = useState<number>(37000);
  const [rate, setRate] = useState<number>(650);

  const darkMode = useDarkMode();

  const debouncedChange = useRef(_.debounce(onChange, 500, { maxWait: 700 }));

  const handleRateChange = (e: any, value: number | number[]) => {
    const max = Math.ceil(((value as number) * 75) / 10000) * 10000;
    setMaxSalary(max);
    setRate(value as number);
    if (salary > max) 
      setSalary(Math.min(salary as number, max));
  };

  const handleSalaryChange = (e: any, value: number | number[]) => {
    setSalary(value as number);
  };

  useEffect(() => {
    debouncedChange.current(salary, rate);
  }, [salary, rate, debouncedChange]);

  return (
    <Box maxWidth={600} marginBottom={2}>
      <Body color={darkMode ? "white" : "black"}>
        Vi erbjuder en rörlig lönemodell där alla medarbetare går in på samma
        villkor redan från första dagen. Vi har slopat löneförhandlingarna helt.
        Istället får varje medarbetare sätta sin egen lön, bestämma över sin
        pensionsavsättning och kompetensutveckling. Vill du ha tjänstebil, ny
        telefon, dator, friskvård, gå kurser, mer fritid, det är upp till dig
        att bestämma.
      </Body>
      <Box margin={4}>
        <Caption color={darkMode ? "white" : "black"}>Önskad lön</Caption>
        {darkMode ? (
          <DarkSlider
            defaultValue={37000}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            color="primary"
            step={1000}
            min={30000}
            max={maxSalary}
            onChange={handleSalaryChange}
          />
        ) : (
          <Slider
            defaultValue={37000}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            color="primary"
            step={1000}
            min={30000}
            max={maxSalary}
            onChange={handleSalaryChange}
          />
        )}
      </Box>
      <Box margin={4}>
        <Caption color={darkMode ? "white" : "black"}>Arvode kr/h</Caption>
        {darkMode ? (
          <DarkSlider
            defaultValue={650}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            color="primary"
            value={rate}
            step={10}
            min={600}
            max={1200}
            onChange={handleRateChange}
          />
        ) : (
          <Slider
            defaultValue={650}
            getAriaValueText={valuetext}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="on"
            color="primary"
            value={rate}
            step={10}
            min={600}
            max={1200}
            onChange={handleRateChange}
          />
        )}
      </Box>
    </Box>
  );
};
